(function ($) {
  Drupal.behaviors.stickyMenuFormatterV1 = {
    attach: function (context) {
      var $template = $('.js-sticky__menu__formatter', context);

      // if(location.pathname == '/'){
      //   $('body').addClass('body--homepage');
      // }else{
      //   $('body').addClass('body--interior');
      //   return;
      // }

      $template.each(function (index, el) {
        var $context = $(this);
        var $emailTrigger = $('.js-sticky__menu__link--signup', $context);
        var $liveChatTrigger = $('.js-sticky__menu--livechat', $context);
        var $subnav = $('.js-sticky__menu__subnav', $context);
        var $subnavClose = $('.js-sticky__menu__subnav--close', $context);
        var $liveChatNav = $('.js-sticky__menu__livechat', $context);
        var $offerTrigger = $('.js-sticky__menu--offers', $context);
        var $offerMenu = $('.js-sticky__footer__offer', $context);
        var $offerItems = $('.js-sticky__offer__items', $context);
        var $offerClose = $('.js-sticky__offer__close', $context);
        var offerClosed = 1;

        $emailTrigger.once().on('click', function (event) {
          event.preventDefault();
          $context.toggleClass('sticky__menu__subnav__active');
          $subnav.slideToggle('fast');
        });

        $(document).on('sticky_email_open', function (event, el) {
          $context.toggleClass('sticky__menu__subnav__active');
          $subnav.slideToggle('fast');
        });

        // on click outside
        $('.page-wrapper')
          .once()
          .on('click', function (event) {
            var $closestStickyNav = $(event.target).closest('.js-sticky__menu__subnav');
            var $closestSubnav = $(event.target).closest('.js-sticky__menu__formatter');

            if (!$closestStickyNav.length && !$closestSubnav.length) {
              if ($context.hasClass('sticky__menu__subnav__active')) {
                $context.removeClass('sticky__menu__subnav__active');
                $subnav.slideToggle('fast');
              }
            }
          });

        $subnavClose.once().on('click', function (event) {
          event.preventDefault();
          $context.toggleClass('sticky__menu__subnav__active');
          $subnav.slideToggle('fast');
        });

        $liveChatTrigger.once().on('click', function (event) {
          event.preventDefault();
          var $liveChatLink = $('.js-sticky-livechat-liveperson-trigger a[data-lp-event="click"]');

          if ($liveChatLink.length > 0) {
            $($liveChatLink)[0].click();
          }
        });

        $offerTrigger.once().on('click', function (event) {
          event.preventDefault();
          /**
           * The mobile offer slider controls are in the JS for
           * sticky_offer_formatter_v1 now, so trigger the click
           * for that hidden element here.
           */
          if ($('.js-sticky__offer__formatter').length > 0) {
            $('.js-sticky__offer__formatter').trigger('click');
          }
        });

        $offerClose.once().on('click', function (event) {
          event.preventDefault();
          if (!offerClosed) {
            $offerMenu.removeClass('sticky__offer__mobile');
            setTimeout(function () {
              offerClosed = 1;
              $offerMenu.addClass('sticky__offer__mobile--close');
            }, 10);
          }
        });
      });
    }
  };
})(jQuery);
